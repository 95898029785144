<template>
  <div id="potential-applicants">
    <!-- OVERLAY CARD -->
    <div class="vx-col w-full mb-6">
      <vx-card class="big-title-card-bg-black h-auto">
        <h1 class="rg-big-title extrabold rg-title">
            Alta de <span>prospectos</span> y asignación de <span>proveedores</span>.
        </h1>
      </vx-card>
    </div>
    <vx-card title="Alta de prospectos y asignación de proveedores.">
      <vx-card class="mt-0">
          <p class="mb-4">En esta sección puedes dar de alta prospectos. Primero debes descargar la plantilla aquí. Debes respetar el orden de las columnas que aparecen en la plantilla, sin eliminar ni agregar otras columnas. Te recordamos que previamente debes crear las las Zonas, Regiones y los usuarios de Líderes de venta ya que los necesitarás para asignarlos a los prospectos dentro de la plantilla.</p>
          <vs-divider></vs-divider>
          <p class="bold mb-4 mt-4 pl-2">Filtros</p>
          <div class="flex mb-4">
            <vs-select
                v-if="programIdSelected !== null"
                label="Zonas"
                v-model="zoneIdSelected"
                @change="addFilter('zone_id', $event)"
                class="mr-2">
              <vs-select-item text="Todas" value="0"></vs-select-item>
              <vs-select-item
                  v-for="zone in programSelected.zones"
                  :text="zone.name"
                  :key="zone.id"
                  :value="zone.id"></vs-select-item>
            </vs-select>

            <vs-select
                :disabled="zoneIdSelected == 0 && zoneSelected.regions.length < 1"
                label="Regiones"
                v-model="regionIdSelected"
                @change="addFilter('region_id', $event)">
              <vs-select-item text="Todas" value="0"></vs-select-item>
              <vs-select-item
                  v-for="region in zoneSelected.regions"
                  :text="region.name"
                  :key="region.id"
                  :value="region.id"></vs-select-item>
            </vs-select>
            <vs-button @click.stop="getPotentialApplicants()" type="filled" class="mt-6 ml-4" icon="search"></vs-button>
          </div>
          <!-- TABLE -->
          <div class="row">
          <div class="col-sm-12 col-md-12">
              <div ref="applicants-table">
              <vs-table multiple v-model="selected" stripe search max-items="25" :data="applicants" pagination noDataText="No hay datos disponibles">
                  <div slot="header">
                    <div class="col-sm-12 col-md-12">
                      <vs-button @click="openLayoutDialog()" color="dark">Cargar nuevos</vs-button>
                      <vs-button :disabled="selected.length < 1" @click="showAssignDialog()" color="danger" class="m-4">Asignar múltiple</vs-button>
                      <vs-button v-if="isProgramAdmin" @click="showEvent = true" color="dark">Descargar Prospectos</vs-button>
                      <h5 @click="getPotentialApplicants();" class="stats-color">No. total: <span>{{stats.total}}</span></h5>
                      <vx-tooltip
                        title="Prospectos sin proveedor"
                        color="dark"
                        style="float:left;cursor:pointer;margin-top:-8px"
                        class="mt-1"
                        text="Consulta los prospectos que no han sido asignados a ningún proveedor.">
                        <h5 @click="getPotentialApplicantsNotAssigned()" class="stats-color">Sin proveedor: <span>{{stats.not_assigned}}</span> </h5>
                      </vx-tooltip>
                    </div>
                  </div>
                  <template slot="thead">
                      <vs-th class="bold" v-for="(col, idx) in columns" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
                  </template>

                  <template slot-scope="{data}">
                  <vs-tr v-for="(tr, indextr) in data" :key="indextr" :data="tr">
                      <vs-td>
                        <router-link :to="`#`" class="custom-link">
                            {{tr.customer_uid}}
                        </router-link>
                      </vs-td>   
                      <vs-td>
                        <strong>{{tr.customer_name}}</strong>
                      </vs-td>   
                      <vs-td>
                        <strong>{{tr.owner_name}}</strong>
                        <br> tel. {{tr.country_calling_code}} {{phoneFormat(tr.phone)}}
                      </vs-td>   
                      <vs-td>
                        <strong>Zona:</strong> {{tr.subregion_manager_id !== null && tr.subregion_manager_id > 0 ? tr.subregion_manager.region_manager.region.zone.name : "Sin información"}}
                        <br> 
                        <strong>Región:</strong> {{tr.subregion_manager_id !== null && tr.subregion_manager_id > 0 ? tr.subregion_manager.region_manager.region.name : "Sin información"}}
                      </vs-td>  
                      <vs-td v-if="tr.subregion_manager_id != null && tr.subregion_manager_id > 0">
                        <strong>{{tr.subregion_manager_id}} | {{getFullName(tr.subregion_manager.user)}}</strong>
                        <br> {{tr.subregion_manager.user.email}}
                        <br> tel. {{tr.subregion_manager.user.personal.new_phone.country_calling_code}} {{phoneFormat(tr.subregion_manager.user.personal.new_phone.phone)}}
                      </vs-td> 
                      <vs-td v-else>
                        Sin información
                      </vs-td>
                      <vs-td v-if="tr.supplier">
                        <strong>{{tr.supplier.id}} | {{tr.supplier.tradename}}</strong>
                        <br> {{tr.supplier.email}}
                        <br> tel. {{getPhone(tr.supplier)}}
                        <div v-if="tr.supplier_agent" @click="openInfoAgent(tr.supplier_agent)">
                          <vs-chip transparent color="danger">Responsable</vs-chip>
                        </div>
                      <!-- <div class="clickable" @click="showSupplierInformation(tr.supplier.id)">
                          <vs-chip transparent color="danger">
                          <span class="bold text-xs">Ver informacion del proveedor</span>
                          </vs-chip>
                      </div> -->
                      </vs-td>   
                      <vs-td v-else>
                        <strong>Sin proveedor asignado</strong>
                      </vs-td>
                      <vs-td class="bold">
                        {{tr.project != null ? `${tr.project.id} | ${getFullName(tr.project.client.user)}` : "Sin información"}}
                      </vs-td>
                      <vs-td>
                        <vs-chip color="orange">{{translateStatus(tr.status)}}</vs-chip>
                      </vs-td>  
                      <vs-td>
                        {{dateTimeFormat(tr.created_at)}}
                      </vs-td>   
                      <vs-td>
                      <vx-tooltip
                        title="Editar prospecto."
                        color="dark"
                        style="cursor:pointer;margin-top:-8px"
                        class="mt-1"
                        text="Edita la información básica del prospecto.">
                        <vs-button @click="openEditModal(tr)" type="flat" class="rgred" icon="edit"></vs-button>
                      </vx-tooltip>
                      <vx-tooltip
                        title="Asignar solicitud."
                        color="dark"
                        style="float:left;cursor:pointer;margin-top:-8px"
                        class="mt-1"
                        text="Asigna una solicitud de crédito para este prospecto.">
                        <vs-button @click="openProjectModal(tr)" type="flat" class="p-2" color="dark" icon="assignment"></vs-button>
                      </vx-tooltip>
                      </vs-td>       
                  </vs-tr>
                  </template>
              </vs-table>
              </div>
          </div>
          </div>

      </vx-card>
    </vx-card>

    <!-- DOCUMENT FILES POPUP -->
    <vs-popup title="Alta de prospectos" :active.sync="showDialog">
        <p class="mb-4">Asegúrate que el formato del archivo sea en CSV (*.csv) y se hayan respetado el nombre, orden y número de columnas. El nombre del archivo no es relevante.
                        <br>En caso de que el archivo contenga prospectos que ya existan dentro del programa serán actualizados con los datos que contenga la plantilla.
        </p>
        <download-button
          title="Layout para carga de prospectos"
          actionLink="/programs/kof/PROSPECTOS_LAYOUT_RG.csv"
          fileName="PROSPECTOS_LAYOUT_RG.csv"
          :remoteFile="true" />
        <input
            class="m-4"
            ref="fileInput"
            id="layout"
            text="Seleccione el layout"
            accept=".csv"
            fileName="file" 
            type="file" /> <br>
        <vs-button @click="requestPostLayout()" class="m-4" color="dark">Cargar</vs-button>
    </vs-popup>

    <!-- ASIGNAR PROVEEDOR MULTIPLE -->
    <vs-popup title="Asignación a proveedor" :active.sync="showFormPromptSupplierAssign">
      <h6 class="subtitle-orange mb-4">Asigna un proveedor a los siguientes prospectos.</h6>
      <vx-card>
        <!-- SELECTOR -->
        <div class="mb-4">
          <label for="">Proveedores:</label>
            <v-select
              label="text"
              v-model.lazy="supplierSelected"
					    class="vs-custom w-full"
              v-validate="'required'"
              name="sourceType"
              placeholder="Seleccione una opción"
              :clearable="false"
					    :searchable="true"
              :options="getCountriesList"
              :reduce="text => text.value"
              >
              <div slot="no-options">No hay coincidencias</div> 
            </v-select>
        </div>

        <!-- TABLA DETALLE -->
        <vs-table class="mb-4" stripe max-items="25" :data="selected" pagination noDataText="No hay datos disponibles">
          <div slot="header">
          Detalle prospectos.
          </div>
          <template slot="thead">
              <vs-th class="bold" v-for="(col, idx) in columnsAssign" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr v-for="(tr, indextr) in data" :key="indextr" :data="tr">
              <vs-td>
                <router-link :to="`#`" class="custom-link">
                  {{tr.customer_uid}}
                </router-link>
              </vs-td>   
              <vs-td>
                <strong>{{tr.customer_name}}</strong>
              </vs-td>   
              <vs-td>
                <strong>{{tr.owner_name}}</strong>
                <br> tel. {{phoneFormat(tr.owner_phone)}}
              </vs-td>          
            </vs-tr>
          </template>
        </vs-table>
        <div class="justify-end">
          <vs-button :disabled="supplierSelected === null" @click="requestAssignSupplier()" color="danger">Asignar</vs-button>
        </div>
      </vx-card>
    </vs-popup>

    <!-- INFORMACIÓN DEL VENDEDOR -->
    <vs-popup v-if="agent" :active.sync="showAgentInfoPromt" title="Información del responsable">
      <div class="vx-row">
        <div class="vx-col w-full">
        <h6>Aquí se muestra la información del responsable que generó la solicitud</h6>
        </div>
        <div class="vx-col w-full mt-5">
            <vs-input
                class="w-full"
                label="Nombre"
                name="name"
                v-model.lazy="agent.name"
                icon-pack="feather"
                placeholder="Nombre del responsable"
                disabled
            />
        </div>
        <div class="vx-col w-full mt-5">
            <vs-input
                class="w-full"
                label="Teléfono"
                name="phone"
                v-model.lazy="agent.phone"
                icon-pack="feather"
                placeholder="Teléfono"
                disabled
            />
        </div>
        <div class="vx-col w-full mt-5">
            <vs-input
                class="w-full"
                label="Correo electónico"
                name="email"
                v-model.lazy="agent.user.email"
                icon-pack="feather"
                placeholder="Correo electónico"
                disabled
            />
        </div>
      </div>
    </vs-popup>

    <vs-popup v-if="currentPotentialApplicant" :active.sync="showPotentialApplicantPromt" title="Información de prospecto">
      <create-potential-applicant-control
        ref="createPotentialApplicantControl"
        :currentPotentialApplicant="currentPotentialApplicant"
        @on-save="handleOnSave()"
        @on-close="showPotentialApplicantPromt = false"
      />
    </vs-popup>

    <!-- POPUP ASIGNAR VENDEDOR -->
    <vs-popup
      title="Asignar solicitud al prospecto."
      :active.sync="showLoanRequestPrompt">
      <div class="vx-row">

      </div>
      <div class="vx-col w-full">
        <vx-card title="Asignar solicitud al prospecto.">
          <div class="vx-col w-full">
            <p class="bold mb-4">
              Asigna una solicitud de crédito a este prospecto y así mantener alimentado nuestro reporte de desempeño.
            </p>
          </div>
          <div class="vx-col w-full mb-4">
            <div class="flex mb-4">
              <vs-input
                v-model.number="project_id"
                name="pid"
                class="w-1/2"
                placeholder="Buscar proyecto"
              />
              <vs-button :disabled="project_id == null || project_id == 0 || project_id == ''" @click.stop="findProjectById()" type="filled" class=" ml-4" icon="search"></vs-button>
            </div>

            <div class="mb-4" v-if="collections.projects.length > 0">
              <vs-divider></vs-divider>
              <h5>{{collections.projects[0].select_name}}</h5>
            </div>

            <div class="vx-col w-full mb-4">
              <p>Nota: Sólo se pueden visualizar solicitudes que no hayan sido rechazadas o archivadas. </p>
            </div>

            <vs-button :disabled="!project_id" @click="requestAssignProject()" color="dark" class="mr-5 mt-2">Asignar</vs-button>
            <vs-button @click="closeProjectPrompt()" type="flat" color="primary">Cerrar</vs-button>
          </div>
        </vx-card>
      </div>
    </vs-popup>

      <vs-prompt
        style="z-index:54000"
        class="calendar-event-dialog"
        title="Información del evento"
        accept-text="Ir al proyecto"
        cancel-text="Cerrar"
        button-cancel="border"        
        @cancel="closeEventDialog"
        @accept="getCSV"
        :active.sync="showEvent" >  
        <div class="vx-col w-full mb-5 mt-2">
          <p>Filtra los prospectos a descargar selecciona una opción a continuación: </p>
          <ul class="centerx radio-display">
            <li class="mt-5">
              <vs-radio v-model="unassigned" vs-value="0"> Descargar todos los prospectos.</vs-radio>
            </li>
            <li class="mt-5">
              <vs-radio v-model="unassigned" vs-value="1"> Descargar sólo los prospectos no asignados a proveedor.</vs-radio>
            </li>
          </ul>
        </div>
      </vs-prompt>
  </div>
</template>

<script>
  import VxUpload from '@upload/VxUpload';
  import dateHelper from "@mixins/dateHelper";
  import formatHelper from "@mixins/formatHelper";
  import CreatePotentialApplicantControl from "./modals/CreatePotentialApplicantControl.vue"
 
  const columns = [
    { title: "CLIENTE", key: "customer_uid", sortable: true }
    , { title: "NOMBRE CLIENTE", key: "name", sortable: true }
    , { title: "PROSPECTO", key: "retailer", sortable: true }
    , { title: "ZONA / REGIÓN", key: "region_zone", sortable: false }
    , { title: "LÍDER DE VENTA", key: "subregion_manager", sortable: false }
    , { title: "PROVEEDOR", key: "supplier", sortable: false }
    , { title: "PROYECTO", key: "project", sortable: false }
    , { title: "ESTADO", key: "status", sortable: false }
    , { title: "FECHA DE CARGA", key: "created_at", sortable: true }
    , { title: "ACCIONES", key: "", sortable: false }
  ]; 
  
  const columnsAssign = [
    { title: "CLIENTE", key: "customer_uid", sortable: true }
    , { title: "NOMBRE CLIENTE", key: "name", sortable: true }
    , { title: "PROSPECTO", key: "retailer", sortable: true }
  ];
export default {
  name: "potential-applicants",
  mixins: [formatHelper,dateHelper],
  data() {
    return {
      showEvent: false
      , unassigned: 0
      , applicants: []
      , columns: columns
      , showDialog: false
      , showFormPromptSupplierInfo: false
      , currentSupplierID: null
      , selected: []
      , showFormPromptSupplierAssign: false
      , columnsAssign: columnsAssign
      , collections: {
          suppliers: []
          , projects: []
        }
      , supplierSelected: null
      , showAgentInfoPromt: false
      , agent: null
      , showPotentialApplicantPromt: false
      , currentPotentialApplicant: null
      , stats: {
        not_assigned: 0 
        , total: 0
      }
      , filters: {}
      , programs: []
      , zoneIdSelected: 0
      , regionIdSelected: 0
      , showLoanRequestPrompt: false
      , project_id: null
      , currentCustomer: null
    }
  },
  components: {
    VxUpload,
    CreatePotentialApplicantControl
  },
  async mounted() {
    const {data} = await axios.get(`/api/pgm/me/programs`)
    this.programs = data;
    // this.getPotentialApplicants();
    this.getCollections();
    this.getPotentialApplicantsStats();
  },
  computed: {
    getCountriesList() {
			return this.collections.suppliers.map((supplier) => ({ value: supplier.id, text: supplier.select_name }));
		},
    programSelected() {
      let program = this.programs.filter(p => p.id === this.programIdSelected)
      return program[0] ?? null;
    },
    zoneSelected() {
      let zone = this.programSelected.zones.filter(z => z.id === this.zoneIdSelected)
      return zone[0] ?? {regions: []};
    },
    isProgramAdmin() {
      return this.$acl.check("isProgramAdmin");
    },
    programIdSelected () {
     return this.currentProgramData.id;
    }
  },
  methods: {
    closeEventDialog(){
      this.showEvent = false;
    },
    handleOnSave() {
      this.getPotentialApplicants();
      this.currentPotentialApplicant = null;
    },
    async getPotentialApplicants() {
      try {
        this.showLoading(true);
        const response = await axios.get(`/api/kof/get/potentialApplicants?region=${this.regionIdSelected}&programId=${this.currentProgramData.id}`);
        this.applicants = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.showLoading(false);
      }
    },
    async getPotentialApplicantsStats() {
      try {
        this.showLoading(true);
        const response = await axios.get(`/api/kof/get/pgm-potential-applicants/stats?programId=${this.currentProgramData.id}`);
        this.stats = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.showLoading(false);
      }
    },
    async getPotentialApplicantsNotAssigned() {
      try {
        this.showLoading(true);
        const response = await axios.get(`/api/kof/get/pgm-potential-applicants/not-assigned`);
        this.applicants = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.showLoading(false);
      }
    },
    async getCollections(){
      try {
        let collectionsObjects = ['getSuppliers'];
        let params = "with[]=" + collectionsObjects.join("&with[]=");
        let res = await axios.get(`/api/v1/forms/getFormCollections?${params}`);
        this.collections.suppliers = res.data.getSuppliers;
      }
      catch (e) {
        this.warn(e);
      }
    },
    async requestPostLayout() {
      let ok = document.getElementById("layout").files.length > 0;
      if(!ok){
        this.missingFieldsNotif();
        return;
      } 
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Cargar layout de prospectos.',
        text: `¿Estás seguro que deseas cargar el layout? La BD existente será actualizada.`,
        acceptText: "Cargar layout",
        cancelText: 'Cancelar',
        accept: this.doPostLayout
      });
    },
    async doPostLayout() {
      try {
        let doc = document.getElementById("layout");
        const data = new FormData();
        if(doc.files.length > 0){
          this.showLoading(true);
          data.append("applicants_layout", doc.files[0]);
          const res = await axios.post(`/api/kof/post/potentialApplicants/layout?programId=${this.programIdSelected}`, data);
          const sessionDate = this.midDateFormat(new Date());
          
          // COMPLEMENTA EL STREAM DE ARCHIVOS CON UN MAGÍA DEL DOM
          const fileURL = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `CARGA_LAYOUT_SESION_${sessionDate}.csv`);
          document.body.appendChild(fileLink);
          fileLink.click();
          doc.value = "";
          this.showDialog = false;
          await this.getPotentialApplicants();
          await this.getPotentialApplicantsStats();
          this.showLoading(false);
          this.saveSuccessNotif();
        } else {
          this.warningNotif("Importante", "No se ha seleccionado ningún layout para cargar.");
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.showLoading(false);
      }
    },
    requestAssignSupplier() {
      let ok = this.supplierSelected !== null;
      if(!ok) {
        this.missingFieldsNotif();
        return;
      }
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Asignar proveedor a prospectos.',
        text: `¿Estás seguro que deseas asignar el proveedor <<${this.supplierSelected}>> a estos prospectos? La BD existente será actualizada.`,
        acceptText: "Asignar proveedor",
        cancelText: 'Cancelar',
        accept: this.doPostAssignSupplier
      });
    }, 
    async doPostAssignSupplier() {
      try {
        this.showLoading(true);
        const payload = {
          customer_uids: this.selected.map(item => item.customer_uid)
          , supplier_id: this.supplierSelected
        };
        if(payload.customer_uids.length === 0 || payload.supplier_id === null){
          this.warningNotif("Importante", "No se han seleccionado la información necesaria para asignar proveedor.");
          this.showLoading(false);
          return;
        }
        await axios.post(`/api/kof/post/potentialApplicants/assign-supplier`, payload);
        this.showLoading(false);
        await this.getPotentialApplicants();
        this.onCloseAssignDialog();
        this.saveSuccessNotif();
      } catch (error) {
        this.showLoading(false);
        console.error(error);
      }
    },
    showAssignDialog(){
      this.supplierSelected = null;
      this.showFormPromptSupplierAssign = true;
    },    
    onCloseAssignDialog(){
      this.supplierSelected = null;
      this.selected = [];
      this.showFormPromptSupplierAssign = false;
    },
    openLayoutDialog() {
      document.getElementById("layout").value = "";
      this.showDialog = true;
    },
    showSupplierInformation(sid) {
      this.currentSupplierID = sid;
      this.showFormPromptSupplierInfo = true;
      this.$refs.supplierDetails.update(sid);
      this.$refs.supplierDetails.getSupplierAgents(sid);
    },
    openInfoAgent(agent){
      this.agent = null;
      this.agent = agent;
      this.showAgentInfoPromt = true;
    },
    translateStatus(status){
      let statusName = "N/A";
      switch(status){
        case 0: 
          statusName = "Pendientes de contactar";
          break;
        case 1: 
          statusName = "Descartados por instalador";
          break;
        case 2: 
          statusName = "Descartados sin interés del cliente";
          break;
        case 3: 
          statusName = "Interesados en recibir propuesta";
          break;
        case 4: 
          statusName = "Con propuesta realizada ";
          break;
        case 5: 
          statusName = "Instalado sin financiamiento";
          break;
        case 6: 
          statusName = "Problemas en comunicación";
          break;
      }
      return statusName;
    },
    openEditModal(currentItem) {  
      this.currentPotentialApplicant = currentItem;
      this.showPotentialApplicantPromt = true;
    },
    getFullName(currentItem) {
      let fullName = "";
      if(currentItem.personal){
        fullName = currentItem.personal.first_name;
        if(currentItem.personal.second_name)
            fullName += ` ${currentItem.personal.second_name}`;
  
        fullName += ` ${currentItem.personal.last_name_1}`;
  
        if(currentItem.personal.last_name_2)
            fullName += ` ${currentItem.personal.last_name_2}`;
      } else {
        fullName = currentItem.business.name;
      }
      return fullName;
    },
    getPhone(currentItem) {
      let phone = "--";

      if(currentItem.user.personal){
        if(currentItem.user.personal.new_phone) {
          phone = currentItem.user.personal.new_phone.country_calling_code + " " + this.phoneFormat(currentItem.user.personal.new_phone.phone);
        }
      } else {
        if(currentItem.user.business.new_phone){
          phone = currentItem.user.business.new_phone.country_calling_code + " " + this.phoneFormat(currentItem.user.business.new_phone.phone);
        }
      }

      return phone;
    },
    async addFilter(filterName, ev) {
      if (ev.toString() === "0") {
        delete this.filters[filterName];
      } else {
        this.filters[filterName] = ev.toString();
      }
      if(filterName == "zone_id" && ev.toString() === "0"){
          this.zoneIdSelected = 0;
          this.regionIdSelected = 0;
      }
    },
    openProjectModal(item){
      this.currentCustomer = item;
      this.showLoanRequestPrompt = true;
    },
    closeProjectPrompt() {
      this.showLoanRequestPrompt = false;
      this.project_id = null;
      this.currentCustomer = null;
      this.collections.projects = [];
    },
    async findProjectById(){
      try {
        this.showLoading(true);
          const role = "single-project";
          const id = this.project_id;
          let res = await axios.get(`/api/get/v1/forms/loan-request/list/${role}/${id}`);
          this.collections.projects = res.data;
        this.showLoading(false);
      } catch (e) {
        this.warn(e);
        this.showLoading(false);
      }
    },
    async requestAssignProject() {
      let ok = this.project_id == null || this.project_id == 0 || this.project_id == '';
      if(ok){
        this.missingFieldsNotif();
        return;
      }
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Asignar solicitud a prospecto.',
        text: `¿Estás seguro que deseas asignar el la solicitud a este prospecto?`,
        acceptText: "Asignar solicitud",
        cancelText: 'Cancelar',
        accept: this.doPostAssignProject
      });
    },
        async doPostAssignProject(){
      this.showLoading(true);
      try {
        const payload = {pgm_potential_applicant: this.currentCustomer.id, project_id: this.project_id};
        await axios.post('/api/kof/post/loan-request/assign', payload);
        await this.getPotentialApplicants();
        this.closeProjectPrompt();
        this.saveSuccessNotif();
      }
      catch (error) {
        this.warn(error);
        this.failedOperationNotif();
      }
      this.showLoading(false);
    },
    getCSV() {
      try {
        let url = `${this.ApiDomain}/api/get/pgm-potential-applicants/${this.unassigned}/geo?access_token=${this.AccessToken}`;
        window.open(url, '_blank');
      } catch (error) {
        console.log(error);
      }
    }
  }
}
</script>
<style>
  div.vx-card__title > h4 {
    font-weight: bolder;
  }
  .subtitle-orange {
    color: orange;
    font-weight: bold;
  }
  div.con-vs-chip {
    min-width: 95%;
  }
  .custom-link {
    color: #22292f!important;
    font-weight: 500;
    margin-right: 0.5rem;
    text-decoration: revert !important;
  }
  .con-vs-dialog {
    z-index: 90000 !important;
  }
  .stats-color > span {
    color: #f26d61;
  }
</style>